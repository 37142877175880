
function getToken() {
  let token = "Bearer " + sessionStorage.token;
  return token;
}

function getDeviceId() {
  return localStorage.getItem('deviceID');
}

function buildUrl(baseUrl, endpoint) {
  return `${baseUrl}/${endpoint}`;
}

var headers = {
  "Authorization": getToken()
};

class RestService {
  constructor() {
    // this.domain = "http://192.168.1.28:8085/softmint-upi"; //local url
    this.domain = "https://bc.easyswift.in"; //live url
    // this.domain = "https://m.softmintindia.com"; //live url
    // this.domain = "https://uat.softmintdigital.com/upi"; 

  }

  FAILED = 0;
  SUCCESS = 1;
  WRONG_OTP = 3;
  DOWN_TIME = 18;
  NOT_PERMITTED = 2;
  SOMETHING_WENT_WRONG = 3;
  PENDING = 4;
  EXCEPTIONAL_ERROR = 5;
  OTP_SUCCESS = 0x6; git
  OTP_VERIFY = 0x7;
  NOT_IMPLEMENTED = 0x8;
  NSDL_AGENT_NOT_ACTIVATED = 0x9;
  DONE_ALREADY = 0xA;
  VIEW_BBPS = 0xB;
  NOT_VIEW_BBPS = 0xC;
  BBPS_RECHARGE_ADD_PARAM = 0xD;
  BBPS_NOT_RECHARGE_ADD_PARAM = 0xE;
  SESSION_EXPIRED = 0xF;
  INVALID_TOKEN = 0x10;
  INVALID_HEADER = 0x11;
  DOWNTIME = 0x12;
  NOT_EXIST = 0x13;
  AGENT_NOT_FOUND = 0x14;
  NSDL_AGENT_NOT_ACTIVE = 0x20;



  //ROLE CONSTATNTS

  ADMIN = 0;
  API_USER = 1;
  MASTER_ADMIN = 2;
  MASTER_DISTRIBUTOR = 3;
  DISTRIBUTOR = 4;
  RETAILER = 5;
  CUSTOMER = 6;
  EMPLOYEE = 7;
  FRANCHISE = 8;


  roles = {
    0: 'ADMIN',
    1: 'API USER',
    2: 'DISTRIBUTOR',
    3: 'SUB DISTRIBUTOR',
    4: 'EMPLOYEE',
    5: 'NA',
    6: 'NA',
    7: 'NA',
    8: 'FRANCHISE',
    9: 'UPI USER',
    10: 'SUB UPI USER',
  };

  services = {
    1: 'AEPS',
    2: 'PAYOUT',
    3: '',
    4: '',
    5: '',
    6: '',
    7: '',
    8: '',
    9: '',
    10: '',
  };


  // async fetchUserBalance(url = "user/fetchUserBalance") {
  //   var myHeaders = new Headers();
  //   myHeaders.append("Authorization", `Bearer ${sessionStorage.getItem("token")}`);

  //   var requestOptions = {
  //     method: 'POST',
  //     headers: myHeaders,
  //     redirect: 'follow'
  //   };

  //   url = buildUrl(this.domain, url);
  //   console.log(requestOptions, url);
  //   return await fetch(url, requestOptions)
  // }

  // async fetchDashboardData(url = "user/getDashboardDetails") {
  //   var myHeaders = new Headers();
  //   myHeaders.append("Authorization", `Bearer ${sessionStorage.getItem("token")}`);

  //   var requestOptions = {
  //     method: 'POST',
  //     headers: myHeaders,
  //     redirect: 'follow'
  //   };

  //   url = buildUrl(this.domain, url);
  //   console.log(requestOptions, url);
  //   return await fetch(url, requestOptions)
  // }

  // async fetchServiceWiseData(request) {
  //   var myHeaders = new Headers();
  //   myHeaders.append("Authorization", `Bearer ${sessionStorage.getItem("token")}`);

  //   var requestOptions = {
  //     method: 'POST',
  //     headers: myHeaders,
  //     body: JSON.stringify(request),
  //     redirect: 'follow'
  //   };
  //   const url = buildUrl(this.domain, "report/fetchServiceWiseTxnAmount");
  //   console.log(requestOptions, url);
  //   return await fetch(url, requestOptions)
  // }

  async validateToken(url = "auth/validateToken") {

    var requestOptions = {
      method: 'POST',
      redirect: 'follow',
      body: getToken()
    };
    url = buildUrl(this.domain, url);
    console.log(requestOptions, url);
    return await fetch(url, requestOptions)
  }


  async request(url, method = "POST", request = null) {
    const options = {
      method
    };
    if (request !== null) {
      options.body = JSON.stringify(request);
      headers["Content-type"] = "application/json";
      if (url === "auth/authenticateUser") {
        headers["deviceId"] = getDeviceId();
        delete headers["Authorization"];

      } else if (url === "user/fetchUserBalance") {
        headers["Authorization"] = "Bearer " + getToken();
        delete headers["deviceId"];
      }
      options.headers = headers;
    } else {
      delete headers["Content-type"];
      options.headers = headers;
    }

    url = buildUrl(this.domain, url);
    console.log(url, options);
    return await fetch(url, options);
  }

  requestFormData(url, method = "POST", formdata = new FormData()) {
    const options = {
      method,
    };

    url = buildUrl(this.domain, url);
    if (formdata) {
      options.body = formdata;
    }
    console.log(url, options);
    return fetch(url, options);
  }


  async fetchDashboardData(url = "user/dashboardDetails") {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${sessionStorage.getItem("token")}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow"
    }

    url = buildUrl(this.domain, url);
    console.log(requestOptions, url);
    return await fetch(url, requestOptions)
  };

  async post(url, data) {
    const method = "POST";
    const response = this.request(url, method, data).then(response => response.json());
    const responseData = await response;
    if (responseData.status === this.INVALID_TOKEN) {
      window.location.href = "/";
      window.localStorage.clear();
      window.sessionStorage.clear();
    }
    return await response;
  }

  postFormData(url, formdata) {
    const method = "POST";
    return this.requestFormData(url, method, formdata).then(response => response.json());
  }

  async get(url, id) {
    const method = "GET";
    if (id) {
      url = `${url}/${id}`;
    }

    const response = this.request(url, method).then((response) => response.json());
    const responseData = await response;
    if (responseData.status === this.INVALID_TOKEN) {
      window.location.href = "/";
      window.localStorage.clear();
      window.sessionStorage.clear();
    }
    return response;

    // return ;
  }

  delete(url, id) {
    const method = "DELETE";
    if (id) {
      url = `${url}/${id}`;
    }
    return this.request(url, method).then((response) => response.json());
  }

  formatDate(utcdate) {
    const inputDate = new Date(utcdate);

    const year = inputDate.getFullYear();
    const month = String(inputDate.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const day = String(inputDate.getDate()).padStart(2, '0');

    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  }

}


export default RestService;