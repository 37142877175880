// LazyToastContainer.js
import React, { Suspense } from 'react';
import { ToastContainer } from 'react-toastify';


// CSS ko lazy load karna
const LazyCSS = React.lazy(() => import('./LazyToastifyCSS'));

const LazyToastContainer = () => (
  <Suspense fallback={null}>
    <LazyCSS />
    <ToastContainer />
  </Suspense>
);

export default LazyToastContainer;
