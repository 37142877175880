import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { FaRegEdit } from 'react-icons/fa';
import Qrcode from './Qrcode';
import RestService from '../http';

function SoftmintQr() {

    const service = new RestService();
    const [show, setShow] = useState(true);
    const [minutes, setMinutes] = useState(5);
    const [seconds, setSeconds] = useState(0);
    const [language, setLanguage] = useState('English');
    const [orderId, setOrderId] = useState('');
    

    const [data, setData] = useState({
        "expiryDate": "",
        "amount": "",
        "orderId": "",
        "responseMessage": "",
        "status": "",
        "responseCode": "00",
        "sellerIdentifier": "",
        "payeeName": "",
        "vpa": "",
        "intenturl": ""
    });


    const texts = {
        English: {
            payWithUPI: 'PAY WITH UPI QR',
            scanQR: 'Scan the QR using any UPI app on your phone.',
            qrValidFor: 'QR Code is valid for',
            preferredPaymentMethods: 'PREFERRED PAYMENT METHODS',
            saveChanges: 'Save Changes',
        },
        Hindi: {
            payWithUPI: 'यूपीआई क्यूआर के साथ भुगतान करें',
            scanQR: 'अपने फोन पर किसी भी यूपीआई ऐप का उपयोग करके क्यूआर को स्कैन करें।',
            qrValidFor: 'क्यूआर कोड मान्य है',
            preferredPaymentMethods: 'पसंदीदा भुगतान विधियाँ',
            saveChanges: 'परिवर्तनों को सुरक्षित करें',
        },
    };


    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const encodedData = urlParams.get('d');

        if (encodedData) {
            try {
                const decodedData = decodeURIComponent(encodedData || '');
                const base64Decoded = atob(decodedData);
                const receiptData = JSON.parse(base64Decoded);
                setOrderId(receiptData.orderId);
                setData(receiptData);
                console.log(receiptData);
            } catch (error) {
                console.error('Error parsing JSON:', error);
            }
        }
    }, []);

    useEffect(() => {
        const timer = setInterval(() => {
            if (seconds > 0) {
                setSeconds(seconds - 1);
            } else if (minutes > 0) {
                setMinutes(minutes - 1);
                setSeconds(59);
            }
        }, 1000);

        return () => clearInterval(timer);
    }, [minutes, seconds]);

    useEffect(() => {
        if (minutes === 0 && seconds === 0) {
            setShow(false);
        }
    }, [minutes, seconds]);

    useEffect(() => {
        const disableReload = (e) => {
            e.preventDefault();
            e.stopPropagation();
            // if (e.key === 'F5' || (e.ctrlKey && e.key === 'r')) {

            // }
        };

        window.addEventListener('keydown', disableReload);

        return () => {
            window.removeEventListener('keydown', disableReload);
        };
    }, []);


    // const handleIntentStatusCheck = async() => {
    //     setOrderId(data.orderId);

    //     var raw = {
    //          "orderId": orderId
    //     }

    //     const result = await service.post("api/checkIntentStatus", raw);
    //     console.log(result);
    // }

    useEffect(() => {
        if (orderId) {
            const intervalId = setInterval(() => {
                handleIntentStatusCheck();
            }, 30000);

            return () => clearInterval(intervalId);
        }
    }, [orderId]);

    const handleIntentStatusCheck = async () => {
        console.log('Order ID before API call:', orderId);

        const raw = {
            orderId: orderId
        };

        try {
            const result = await service.post("api/checkIntentStatus", raw);
            console.log(result);
        } catch (error) {
            console.error("Error checking intent status:", error);
        }
    };


    return (
        <div>
            <Modal show={show} animation={false} className='upiModal' style={{ background: "#b6c2cf" }}>
                <Modal.Header closeButton style={{ background: "royalblue" }}>
                    <div className='row'>
                        <div className='col-md-5'>
                            <img
                                src='../assets/images/softmintlogo.png'
                                alt='logo'
                                style={{ width: "150px", background: "white", padding: "10px", borderRadius: "5px" }}
                            />
                        </div>

                        <div className='col-md-5 text-white' style={{ lineHeight: "0.6", marginLeft: "40px" }}>
                            {/* <h4>Softmint</h4> */}
                            <h4>{data.payeeName}</h4>
                            <p>₹ {data.amount}</p>
                        </div>

                    </div>
                </Modal.Header>

                <div>
                    <select
                        style={{ width: "100%", height: "30px", background: "#2a2aff", color: "white" }}
                        value={language}
                        onChange={(e) => setLanguage(e.target.value)}
                    >
                        <option value='English'>English</option>
                        <option value='Hindi'>Hindi</option>
                    </select>

                    <Modal.Body>
                        <div className="input-wrapper">
                            <input
                                className='form-control text-muted'
                                type='text'
                                value={data.vpa}
                                style={{ fontSize: "15px" }}
                            />
                            <FaRegEdit className="input-icon" />
                        </div>

                        <div className='text-muted mt-3'>
                            {texts[language].payWithUPI}
                        </div>

                        <div className='row m-1' style={{ border: "1px solid lightgrey", borderRadius: "5px", paddingTop: "4px" }}>
                            <div className='col-md-6'>
                                <Qrcode value={data.intenturl} size={130} />
                            </div>

                            <div className='col-md-6'>
                                <p>{texts[language].scanQR}</p>

                                <div style={{ display: "flex", justifyContent: "space-evenly" }}>
                                    <img src='../assets/images/softmint qr_ImgID1.png' alt='paytm' width={20} />
                                    <img src='../assets/images/softmint qr_ImgID2.png' alt='phonepe' width={20} />
                                    <img src='../assets/images/softmint qr_ImgID3.png' alt='gpay' width={20} />
                                    <img src='../assets/images/softmint qr_ImgID4.png' alt='upi' width={20} />
                                </div>

                                <p className='mt-4'>
                                    {texts[language].qrValidFor} {minutes}:{seconds < 10 ? `0${seconds}` : seconds} {language === 'English' ? 'minutes' : 'मिनट'}
                                </p>
                            </div>
                        </div>

                        {/* <div className='text-muted mt-2'>
                            {texts[language].preferredPaymentMethods}
                        </div> */}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary">
                            {texts[language].saveChanges}
                        </Button>
                    </Modal.Footer>
                </div>
            </Modal>
        </div>
    )
}

export default SoftmintQr