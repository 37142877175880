import React, {useState} from 'react';
import {
    CDBSidebar,
    CDBSidebarContent,
    CDBSidebarFooter,
    CDBSidebarHeader,
    CDBSidebarMenu,
    CDBSidebarMenuItem,
} from 'cdbreact';
import { NavLink } from 'react-router-dom';
import Swal from 'sweetalert2';
import { FaQuestionCircle, FaUserCircle } from 'react-icons/fa';

function DemoSidebar() {

    const [isCollapsed, setIsCollapsed] = useState(false);


    const logout = () => {
        Swal.fire({
            title: "Are you sure you want to logout ?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            buttons: {
                confirm: {
                    text: "YES",
                    className: "btn btn-primary",
                },
                cancel: {
                    text: "No",
                    className: "btn btn-danger",
                },
            },
            dangerMode: true,
        }).then((confirmed) => {
            if (confirmed.isConfirmed) {
                // Redirect to the login page (this is just an example)
                window.location.href = "/";
                sessionStorage.removeItem("token");
                sessionStorage.removeItem("user");
            }
        });
    };

    return (
        <>
            <div className={`sidebar ${isCollapsed ? 'collapsed' : ''}`} style={{ display: 'flex', height: '100vh', overflow: 'scroll initial' }}>
                <CDBSidebar style={{ color: "#fff", background: "linear-gradient(190deg, #fff , #284e53,  #181221)" }}>
                    <CDBSidebarHeader prefix={<i className="fa fa-bars fa-large text-dark"></i>}>
                        <a href="/" className="text-decoration-none">
                            <img src="../assets/images/softmintlogo.png" alt="Logo" style={{ width: "150px" }} />
                        </a>
                    </CDBSidebarHeader>

                    <CDBSidebarContent className="sidebar-content" >
                        <CDBSidebarMenu>
                            <NavLink exact to="/fetchusers" activeClassName="activeClicked">
                                <CDBSidebarMenuItem icon="columns">Dashboard</CDBSidebarMenuItem>
                            </NavLink>

                            <NavLink exact to="" activeClassName="activeClicked">
                                <CDBSidebarMenuItem icon="user">Transactions</CDBSidebarMenuItem>
                            </NavLink>

                            <NavLink exact to="/profile" activeClassName="activeClicked">
                                <CDBSidebarMenuItem icon="table">Reports</CDBSidebarMenuItem>
                            </NavLink>

                            <NavLink exact to="/fetchusers" activeClassName="activeClicked">
                                <CDBSidebarMenuItem icon="table">Manage Users</CDBSidebarMenuItem>
                            </NavLink>

                            <NavLink exact to="/kyc" target="_blank" activeClassName="activeClicked">
                                <CDBSidebarMenuItem icon="table">Kyc Document</CDBSidebarMenuItem>
                            </NavLink>

                            <NavLink exact to="/kycreport" target="_blank" activeClassName="activeClicked">
                                <CDBSidebarMenuItem icon="table">Kyc Report</CDBSidebarMenuItem>
                            </NavLink>

                            <NavLink exact to="" onClick={logout}>
                                <CDBSidebarMenuItem icon="power-off">Log Out</CDBSidebarMenuItem>
                            </NavLink>
                        </CDBSidebarMenu>
                    </CDBSidebarContent>

                    <CDBSidebarFooter style={{ textAlign: 'center' }}>
                        <div
                            style={{
                                padding: '20px 5px',
                            }}
                        >
                            <div className='card px-1' style={{ color: "black" }}>
                                <div className='px-3'>
                                    <FaQuestionCircle />&nbsp; <span>Help</span>
                                </div>

                                <div className='mt-2 px-2 py-1' style={{ background: "whitesmoke" }}>
                                    <FaUserCircle style={{ color: "green", fontSize: "30px", marginTop: "5px" }} />&nbsp;
                                    <span>7767867856</span>
                                    <p style={{ marginLeft: "35px", fontSize: "13px" }}>Admin</p>
                                </div>
                            </div>
                        </div>
                    </CDBSidebarFooter>
                </CDBSidebar>
            </div>
        </>
    )
}

export default DemoSidebar