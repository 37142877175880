// QRCodeComponent.jsx
import React from 'react';
import QRCode from 'react-qr-code';

const Qrcode = ({ value, size }) => {
  return (
    <div>
      <QRCode value={value} size={size} />
    </div>
  );
};

export default Qrcode;
